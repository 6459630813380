<template>
    
    <section aria-labelledby="payment-and-shipping-heading" class="py-8 md:py-16 lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1 lg:col-end-7">
        <h2 id="payment-and-shipping-heading" class="sr-only">Payment and shipping details</h2>

        <form v-on:submit.prevent>
          <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">

            <div class="border rounded px-4 py-4 mb-10">
                <div class="grid grid-cols-6 gap-4 border-b pb-4 items-start">
                    <p class="col-start-1 text-sm text-gray-600">Contatto</p>
                    <p class="col-start-2 col-end-5 text-sm text-gray-900">{{user.email}}</p>
                    <router-link to="Step1" class="col-start-6 text-xs text-gray-900 justify-self-end">
                    <a href="#">Cambia</a>
                    </router-link>
                </div>
                <div class="grid grid-cols-6 gap-4 pt-4 items-start">
                    <p class="col-start-1 text-sm text-gray-600">Fattura</p>
                    <p class="col-start-2 col-end-5 text-sm text-gray-900">{{user.indirizzo}}, {{user.cap}}, {{user.citta}} {{user.provincia}}</p>
                    <router-link to="Step1" class="col-start-6 text-xs text-gray-900 justify-self-end">
                    <a href="#">Cambia</a>
                    </router-link>
                </div>
            </div>

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Informazioni aggiuntive</h3>

                <div class="mt-6">
                    <div class="mt-1">
                    <input type="text" id="codice-fiscale" placeholder="Codice fiscale (opzionale)" v-model="user.codicefiscale" @input="setUser('codicefiscale', $event.target.value)" class="uppercase block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm"  />
                    </div>
                </div>

                <div class="mt-6">
                    <div class="mt-1">
                    <input type="email" id="pec" placeholder="PEC (opzionale)" v-model="user.pec" @input="setUser('pec', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm"  />
                    </div>
                </div>
            </div>

            <div class="mt-10">
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Pagamento</h3>
              <h4 id="subcontact-info-heading" class="text-sm text-gray-600 mt-1">Per questo corso è disponibile solo il pagamento tramite bonifico bancario. Riceverai le istruzioni dettagliate per eseguire il bonifico nella schermata successiva.</h4>
              <div class="mt-6">
                <div class="border-t border-l border-r py-4 px-6 border-gray-300 rounded-t">
                    <div class="flex gap-x-2 items-center">
                        <input type="radio" id="deposito" name="deposito" value="Deposito bancario" checked>
                        <p class="text-sm font-semibold">Bonifico bancario (anche rate)</p>
                    </div>
                </div>
                <div class="bg-gray-100 border md:px-16 md:py-16 px-8 py-8 rounded-b border-gray-300 text-sm text-center text-gray-600">
                    <p v-html="pagamento"></p>
                </div>
              </div>
            </div>          
            
            <div class="mt-10 pb-12">
              <button type="submit" v-on:click="stepControl()" class="button--big bg-yellow-400 bg-opacity-80 border border-transparent rounded-md text-sm font-medium text-gray-900 hover:bg-yellow-400 hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-900">Completa ordine</button>
              <router-link to="Step1">
                <a href="#" class="text-sm text-gray-900 pl-6">Torna alle informazioni</a>
              </router-link>
            </div>

            <BottomMenu />

          </div>
        </form>
             
    </section>


</template>

<script>
import BottomMenu from './BottomMenu.vue';
import { computed } from 'vue';
import { state, setUser } from './User';
import { state as product } from './Product';
import axios from 'axios';

const user = computed({
    get: () => state.user,    
});

const pagamento = computed({
    get: () => product.pagamento,    
});

const prezzo = computed({
    get: () => product.prezzo,    
});

const shopifyId = computed({
    get: () => product.shopifyId,    
});


export default {
    setup() {
        return {
            user,
            pagamento,
            shopifyId,
            setUser
        }
    },
    methods: {
        stepControl: async function() {
            try {                                
                let id = await axios.post('https://abilitazioni-corsi-core.caprover.shopifive.com/attivazioniecampuses', state.user)
                .then(res => {
                    return res.data.id;
                });
                setUser("id", id);            
                await axios.post('https://ifttt.shopifive.com/webhook/3acdf540-83f3-42c2-8472-1aab3e3e2ace', state.user)
                .then(res => {
                    return res;
                });                
                for (const [key] of Object.entries(state.user)) {
                    setUser(key, '');
                }                
                window.fbq('track', 'Purchase', {
                    currency: "EUR", 
                    value: parseFloat(prezzo.value).toFixed(2),
                    content_ids: [parseInt(shopifyId.value)],
                    content_type: 'product_group',
                    num_items: 1
                });            
                this.$router.push({ name: 'thank-you', params: {id: this.$route.params.id, order: id }});   
            } catch (error) {
                alert("C'è stato un errore, per favore scrivi una email a info@centroformazioneagape.it");
            }            
        }
    },
    mounted() {            
        !state.user.email ? this.$router.push('step1') : null;
    },
    components: {
        BottomMenu
    }
}

</script>